// @ts-nocheck
const resource = {
  "breadcrumb.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "breadcrumb.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "breadcrumb.my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
  "catalog.search-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par"])},
  "common.title.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "common.title.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "common.title.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne chance"])},
  "common.title.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
  "filter.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
  "form.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading form..."])},
  "form.validation.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être coché."])},
  "form.validation.confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne sont pas identiques."])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être une adresse électronique."])},
  "form.validation.invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette date n'est pas valide."])},
  "form.validation.min-length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ce champ a une longueur minimale de ", _interpolate(_named("MinLength")), "."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])},
  "form.validation.vat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un numéro valide, par exemple BE0123 456 789."])},
  "invoice.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "invoice.status.open.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "invoice.status.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" payé"])},
  "invoice.status.paid.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "layouts.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spuntini - Tous pour le horeca"])},
  "product.action.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de connexion"])},
  "product.badge.limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LE HAUT = LE HAUT"])},
  "product.badge.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
  "product.badge.promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente"])},
  "product.icon.frozen-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article congelé"])},
  "product.icon.variable-weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article sur le poids"])},
  "product.message.error.add-to-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de l'ajout. Contactez-nous si le problème persiste."])},
  "product.message.success.add-to-basket": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Produit")), " a été ajouté au panier."])},
  "product.message.warning.maximum-order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre maximum a été atteint."])},
  "product.message.warning.minimum-order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre minimum a été atteint."])},
  "product.order-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
  "product.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
  "product.stock.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de stock"])},
  "search.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de produits, de marques, d'articles, ..."])},
  "search-suggestions.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la recherche de suggestions."])},
  "store.label.opening-hours.closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
  "store.label.opening-hours.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les heures d'ouverture"])},
  "store.label.route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route"])},
  "store.title.contact-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
  "store.title.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur la carte"])},
  "store.title.opening-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures d 'ouverture"])},
  "webshop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webshop"])},
  "webshop.label.i-sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iSales"])},
  "webshop.label.roeselare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webshop clients Roeselare"])}
}
export default resource